import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Today starts Metcon Week!`}</em></p>
    <p>{`Goblet Squat 3×12`}</p>
    <p>{`Hamstring Curl 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`400ft Sled Pull (135/90)`}</p>
    <p>{`200ft Walking Lunge`}</p>
    <p>{`100 Situps`}</p>
    <p>{`75 KBS (53/35)`}</p>
    <p>{`50 Wall Balls (20/14)`}</p>
    <p>{`25 Calorie Assault Bike`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 1/26/19`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      